.Form {
  width: 100%;
  margin-top: -1.5rem;
}

.Form-products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
  border: none;
  margin: 0;
  padding: 0 1rem;
}

.Form-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Form h2 {
  font-size: 2.75rem;
  color: #ffffff;
  margin: 5rem 0 2.25rem;
}

.Form-input {
  display: block;
  appearance: none;
  border-radius: 0;
  background-color: transparent;
  border: 1px solid #6e94a2;
  width: 38rem;
  height: 4rem;
  text-align: center;
  font-style: italic;
  font-size: 1.25rem;
  color: #ffffff;
  margin: 0.75rem 0;
  transition: border 0.33s;
}

.Form-input[name='adrese'] {
  background-color: #380e0c;
  position: fixed;
  left: -500px;
  bottom: -500px;
}

.Form-button {
  display: block;
  appearance: none;
  border: none;
  text-transform: uppercase;
  background-color: #ffffff;
  font-weight: bold;
  margin: 2.25rem 0 0.5rem;
  padding: 1rem 3rem;
  border-radius: 1.5rem;
  font-size: 1rem;
  transition: background-color 0.33s;
}

.Form-input:active,
.Form-input:focus {
  outline: none;
  border: 1px solid #ffffff;
}

.Form-button:active,
.Form-button:focus {
  outline: none;
  background-color: #a7cbd8;
}

.Form-input::placeholder {
  color: #a7cbd8;
}
