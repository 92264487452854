.Notification {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: normal;
    background-color: #0A171C;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    transition: all .33s;
}

.Notification.visible {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

.Notification-text {
    font-size: 1rem;
    font-weight: normal;
    color: #FFFFFF;
    opacity: .66;
    margin: 2rem 0;
}

.Notification-icon {
    width: 1.25rem;
    height: 1.25rem;
    padding-right: 1em;
    opacity: .66;
}