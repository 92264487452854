.Product {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17rem;
  height: 17rem;
  transition: all .33s;
  cursor: pointer;
  margin: 1rem;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 0 1rem rgba(0, 0, 0, .2);
}

.Product-image,
.Product h2 {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Product-image {
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.Product.selected {
  transform: scale3d(1.04, 1.04, 1.04);
}

/* Hide default checkbox */
.Product input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.Product-checkbox {
  position: absolute;
  width: 2.4rem;
  height: 2.4rem;
  bottom: 2.4rem;
  opacity: 0;
  transform: scale3d(.15, .15, .15);
  transition: all .25s;
}

.Product.selected .Product-checkbox {
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.Product h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.75rem;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  padding: 0 1rem;
  background-color: rgba(0, 0, 0, .33);
  margin: 0;
  transition: background-color .25s;
  text-transform: uppercase;
}

.Product.selected h2 {
  background-color: rgba(0, 0, 0, .75);
}