.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  color: #A7CBD8;
}

.App-logo {
  width: 246px;
  pointer-events: none;
  opacity: .7;
}

.App-header {
  display: flex;
  height: 192px;
  flex-direction: column;
  justify-content: center;
}

.App-main {
  display: block;
}

.App-footer {
  font-size: .875rem;
  font-style: italic;
  margin: 1rem 0 2.5rem;
}

.App-footer__more {
  appearance: none;
  border: none;
  background-color: transparent;
  position: fixed;
  left: 3rem;
  bottom: 1rem;
  animation: float 4s infinite;
}

.App-footer__more:focus {
  outline: none;
}

.App-footer__more img {
  opacity: .33;
  width: 5rem;
  height: 5rem;
  transition: all .33s;
}

.App-footer__more.hidden img{
  opacity: 0;
}

@keyframes float {
	0% {
    transform: translatey(-2rem);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
	100% {
    opacity: 0;
    transform: translatey(0rem);
  }
}